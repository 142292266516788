<template>
  <div style="height: 100%;">
    <toggle-switch class="mb10" label="Fahrradtour" fieldTypeId="334" :selectedFilters="selectedFilters" @sliderChanged="filterChanged" />
    <toggle-switch class="mb20" label="Spaziergang" fieldTypeId="334" :selectedFilters="selectedFilters" @sliderChanged="filterChanged" />
    <toggle-switch class="mb10" label="Tour mit Audio" fieldTypeId="335" :selectedFilters="selectedFilters" @sliderChanged="filterChanged" />
    <toggle-switch class="mb20" label="Tour ohne Audio" fieldTypeId="335" :selectedFilters="selectedFilters" @sliderChanged="filterChanged" />
    <!--
    <toggle-switch class="mb10" label="Touren des Tripmeisters" fieldTypeId="333" :selectedFilters="selectedFilters" @sliderChanged="filterChanged" />
    <toggle-switch label="Touren aller Anbietenden" fieldTypeId="333" :selectedFilters="selectedFilters" @sliderChanged="filterChanged" />
  -->
</div>
</template>

<script>
  import { isset } from '@/utils/helpers';

  export default {
    name: 'filterBox',
    components: {
      ToggleSwitch: () =>import('@/components/controls/ToggleSwitch'),
    },
    data() {
      return {
        selectedFilters: new Map(),
      }
    },
    methods: {
      filterChanged(fieldTypeId, label, value){
        var val = this.selectedFilters.get(fieldTypeId);
        if(value) {
          if(isset(val)){

            if(Array.isArray(val)){
              val.push(label);
            }
            else {
              var values = [];
              val = values;
            }
          }
          else{
           var values = [];
           values.push(label);
           val = values; 
         }
         this.selectedFilters.set(fieldTypeId, val);
       }
       else {
        if(isset(val)){
          if(Array.isArray(val)){
            val.splice(val.indexOf(label),1);
            this.selectedFilters.set(fieldTypeId, val);
          }
          else {
            this.selectedFilters.delete(fieldTypeId)
          }
        }
      }
      this.$emit('filtersChanged', this.selectedFilters);
    },
  },
};
</script>
<style lang="scss" scoped>


</style>

